export const datasDoCalendario = [
  {
  date: '31 de Agosto de 2020',
  title: 'Início da convenções partidárias',
  text: '',
},
{
  date: '16 de Setembro de 2020',
  title: 'Fim convenções partidárias',
  text: '',
},
{
  date: '26 de Setembro de 2020',
  title: 'Fim do registros de candidaturas',
  text: '',
},
{
  date: '27 de Setembro de 2020',
  title: 'Início da propaganda eleitoral',
  text: '',
},
{
  date: '15 de Novembro de 2020',
  title: 'Primeiro turno das eleições',
  text: '',
},
{
  date: '29 de Novembro de 2020',
  title: 'Segundo turno das eleições',
  text: '',
},
]